import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './ParticipantList.scss';

import Table from '../Table';

export default class ParticipantList extends Component {
    constructor(props){
        super(props);
        this.headersTable=[
                ' ',
                'Nombre',
                'Empresa',
                'Telefono',
                'Correo',
                'Código',
                'URL'
            ];
        this.state={
            students:[]
        };
        this.certification='';
        this.addParticipant=this.addParticipant.bind(this);
    }
    addParticipant(){
        this.props.history.push('/app/certifications/'+this.certification+'/add');
    }
    componentDidMount() {
        this.certification=this.props.match.params.certification;

        axios.get('/participant',{
                params:{
                    certification:this.certification
                }
            })
            .then(({ data })=>{
                this.setState({ students: data });
            })
            .catch(err => console.log(err));
    }
    render() {
        return (
        <section className='container partslist'>
            <article className='routing'>
                <Link
                    className='routes'
                    to='/app'>Atrás</Link>
                <Link
                    className='routes'
                    to='/app'>X</Link>
            </article>
            <article className='actions'>
                <div className='title'>Participantes</div>
                <button
                    onClick={this.addParticipant.bind(this)}
                    className='blue'>
                    <span>Agregar Participante</span>
                    <span className='shortLabel'>+</span>
                </button>
            </article>
            <title></title>
            <Table headersTable={this.headersTable}>
                {this.state.students.map((student,index)=>
                <tr key={student._id}>
                    <td> </td>
                    <td>{student.name}</td>
                    <td>{student.company}</td>
                    <td>{student.phone}</td>
                    <td>{student.email}</td>
                    <td>{student.code}</td>
                    <td>{student.url}</td>
                </tr>
                )}
            </Table>
        </section>
        );
    }
}
