import React, { Component } from 'react';
import axios from 'axios';
import './CertificationList.scss';

import Table from '../Table';
import CertificationItem from '../CertificationItem';
import InstructorItem from '../InstructorItem';

export default class CertificationList extends Component {
    constructor(props) {
        super(props);
        this.state={
            headersTable:[
                ' ',
                'Codigo',
                'Nombre',
                'País',
                'Ciudad',
                'Fecha',
                'Expiración',
                'Horario',
                'Lugar'
            ],
            instructorsHeaders:[
                ' ',
                'Nombre',
                'País',
                'Fecha',
                'Expiración',
                'código',
                'URL'
            ],
            courses:[],
            instructors:[],
        };
        this.addCertificate=this.addCertificate.bind(this);
        this.watchCertification=this.watchCertification.bind(this);
    }
    addCertificate(){
        this.props.history.push('/app/certifications/add');
    }
    watchCertification(id){
        this.props.history.push('/app/certifications/'+id);
    }
    addInstructor(){
        this.props.history.push('/app/instructors/add');
    }
    watchInstructor(id){
        this.props.history.push('/app/instructors/'+id);
    }
    componentDidMount() {
        axios.get('/certification')
        .then(({ data })=>{
            this.setState({
                courses:data.map((m)=>{
                    return {
                        _id:m._id,
                        code:m.code,
                        name:m.name,
                        country:m.country,
                        city:m.city,
                        date:(new Date(m.date)).toLocaleDateString(),
                        expiration:(new Date(m.expiration)).toLocaleDateString(),
                        time:m.time,
                        place:m.place
                    };
                })
            });
        })
        .catch(err => console.log(err));
        axios.get('/instructor')
        .then(({ data })=>{
            this.setState({
                instructors:data.map((m)=>{
                    return {
                        _id:m._id,
                        name:m.name,
                        country:m.country,
                        date:(new Date(m.date)).toLocaleDateString(),
                        expiration:(new Date(m.expiration)).toLocaleDateString(),
                        code:m.code,
                        url:m.url
                    };
                })
            });
        })
        .catch(err => console.log(err));
    }
    render() {
        const certs=this.state.courses;
        const instructors=this.state.instructors;

        return (
        <section className='container certlist'>
            <article className='actions'>
                <div className='title'>Certificaciónes</div>
                <button
                    onClick={this.addCertificate.bind(this)}
                    className='blue'>
                    <span>Agregar Certificación</span>
                    <span className='shortLabel'>+</span>
                </button>
            </article>
            <Table
                headersTable={this.state.headersTable}>
                {certs.map((course,index)=>
                <CertificationItem
                    key={course._id}
                    cert={course}
                    showItem={this.watchCertification}/>
                )}
            </Table>
            <article className='actions'>
                <div className='title'>Instructores</div>
                <button
                    onClick={this.addInstructor.bind(this)}
                    className='blue'>
                    <span>Agregar Instructor</span>
                    <span className='shortLabel'>+</span>
                </button>
            </article>
            <Table
                headersTable={this.state.instructorsHeaders}>
                {instructors.map((instructor,index)=>
                <InstructorItem
                    key={instructor._id}
                    instructor={instructor}
                    showItem={this.watchInstructor}/>
                )}
            </Table>
        </section>
        );
    }
}
