import React, { Component } from 'react';

export default class CertificationItem extends Component {
    constructor(props) {
        super(props);
        this.showItem = this.showItem.bind(this);
    }

    showItem(item) {
        this.props.showItem(item);
    }

    render() {
        const cert=this.props.cert;

        return (
            <tr onClick={this.showItem.bind(this,cert._id)}>
                <td></td>
                <td>{cert.code}</td>
                <td>{cert.name}</td>
                <td>{cert.country}</td>
                <td>{cert.city}</td>
                <td>{cert.date.toLocaleString()}</td>
                <td>{cert.expiration.toLocaleString()}</td>
                <td>{cert.time}</td>
                <td>{cert.place}</td>
            </tr>
        )
    }
}
