import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import './AppContainer.scss';

import CertificationList from '../CertificationList';
import CertificationForm from '../CertificationForm';
import ParticipantList from '../ParticipantList';
import ParticipantForm from '../ParticipantForm';
import InstructorForm from '../InstructorForm';

import Auth from '../../auth';

class App extends Component {
    constructor(props){
        super(props);
        this.closeSession = this.closeSession.bind(this);
    }
    closeSession() {
        Auth.signout()
        .then((response)=>{
            console.log('response ',response);
            if(response.data.ok){
                this.props.history.push('/login');
            }else {
                //TODO The user is wrong
            }
        });
    }
    render() {
        return (
            <div>
                <header>
                    <div className='img-wrap'>
                        <img src='/ehcgroup.png' alt='ehcgroup logo'/>
                        <button
                            onClick={this.closeSession.bind(this)}>
                            <span>Cerrar Sesión</span>
                            <span className='shortLabel'>X</span>
                        </button>
                    </div>
                </header>
                <Router>
                    <Switch>
                        <Route exact path='/app' component={CertificationList} />
                        <Route path='/app/certifications/add' component={CertificationForm} />
                        <Route path='/app/certifications/:certification/add' component={ParticipantForm} />
                        <Route path='/app/certifications/:certification' component={ParticipantList} />
                        <Route path='/app/instructors/add' component={InstructorForm} />

                    </Switch>
                </Router>
            </div>
            );
    }
}

export default App;
