import React, { Component } from 'react';

export default class InstructorItem extends Component {
    constructor(props) {
        super(props);
        this.showItem = this.showItem.bind(this);
    }

    showItem(item) {
        this.props.showItem(item);
    }

    render() {
        const instructor=this.props.instructor;
        console.log('instructor get: ',instructor);
        return (
            <tr onClick={this.showItem.bind(this,instructor._id)}>
                <td></td>
                <td>{instructor.name}</td>
                <td>{instructor.country}</td>
                <td>{instructor.date.toLocaleString()}</td>
                <td>{instructor.expiration.toLocaleString()}</td>
                <td>{instructor.code}</td>
                <td>{instructor.url}</td>
            </tr>
        )
    }
}
