import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import 'react-day-picker/lib/style.css';
import './CertificationForm.scss';

import validate from '../functions/validator';
import TextInput from '../TextInput';

export default class CertificationForm extends Component {
    constructor(props){
        super(props);
        this.state={
            formIsValid:false,
            formControls:{
                code:{
                    value:'cpp350',
                    valid: true
                },
                name:{
                    value:'CERTIFIED PROFESSIONAL PENTESTER',
                    valid: true,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                        isString: true
                    }
                },
                country:{
                    value:'',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                        isStringWithTilde: true
                    }
                },
                city:{
                    value:'',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                        isStringWithTilde: true
                    }
                },
                date:{
                    value:'',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                        isDate: true
                    }
                },
                expiration:{
                    value:'',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                        isDate: true
                    }
                },
                time:{
                    value:'8:00 - 17:00',
                    valid: true,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                        isStringWithNumberDash: true
                    }
                },
                place:{
                    value:'',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                        isStringNumberSymbol: true
                    }
                },
            }
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goToHome=this.goToHome.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleExpirationChange = this.handleExpirationChange.bind(this);
    }
    goToHome(){
        this.props.history.push('/app');
    }
    handleDateChange(day) {
        this.changeHandler({
            target:{
                name:'date'
              , value:day
            }
        });
    }
    handleExpirationChange(day) {
        this.changeHandler({
            target:{
                name:'expiration'
              , value:day
            }
        });
    }
    changeHandler = event => {
        const name=event.target.name,
            value=event.target.value;

        const updatedControls = {
            ...this.state.formControls
        };
        const updatedFormElement = {
            ...updatedControls[name]
        };
        if(name==='code'){
            updatedControls.name.value={
                cfi150:'CIBERCRIME FORENSIC INVESTIGATOR',
                cfe350:'CIBERCRIME FORENSIC EXPERT',
                cpp350:'CERTIFIED PROFESSIONAL PENTESTER'
            }[value];
            updatedFormElement.value=value;
        }else{
            updatedFormElement.value=value;
        }
        updatedFormElement.touched = true;
        updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

        updatedControls[name] = updatedFormElement;

        let formIsValid=true;
        for (let inputIdentifier in updatedControls) {
            formIsValid=updatedControls[inputIdentifier].valid&&formIsValid;
        }

        this.setState({
            formControls: updatedControls,
            formIsValid: formIsValid
        });
    }

    handleSubmit(event){
        event.preventDefault();
        let result={};
        if(!this.state.formIsValid){
            return;
        }

        Object.keys(this.state.formControls)
            .forEach((name)=>{
                result[name]=this.state.formControls[name].value
            });

        axios.post('/certification',result)
        .then(()=>{
            this.goToHome();
        });
    };

    render() {
        return (
        <section className='container'>
            <article className='routing'>
                <Link to='/app'>Atrás</Link>
                <Link to='/app'>X</Link>
            </article>
            <article className='actions'>
                <div className='title'>Agregar Certificación</div>
            </article>
            <form className='formcert'
                onSubmit={this.handleSubmit}>
                <label className='w160'>
                    Código:
                    <select
                        name='code'
                        value={this.state.formControls.code.value}
                        onChange={this.changeHandler}>
                        <option value='cpp350'>CPP350</option>
                        <option value='cfi150'>CFI150</option>
                        <option value='cfe350'>CFE350</option>
                    </select>
                </label>
                <TextInput
                    classname='w600'
                    label='Nombre:'
                    name='name'
                    type='text'
                    touched={this.state.formControls.name.touched}
                    valid={this.state.formControls.name.valid}
                    value={this.state.formControls.name.value}
                    onChange={this.changeHandler} />
                <TextInput
                    classname='w380'
                    label='País:'
                    name='country'
                    type='text'
                    touched={this.state.formControls.country.touched}
                    valid={this.state.formControls.country.valid}
                    value={this.state.formControls.country.value}
                    onChange={this.changeHandler} />
                <TextInput
                    classname='w380'
                    label='Ciudad:'
                    name='city'
                    type='text'
                    touched={this.state.formControls.city.touched}
                    valid={this.state.formControls.city.valid}
                    value={this.state.formControls.city.value}
                    onChange={this.changeHandler} />
                <label
                    className='w380'>
                    Fecha de Inicio:
                    <DayPickerInput
                        onDayChange={this.handleDateChange}
                        style={{display:'block'}}/>
                </label>
                <label
                    className='w380'>
                    Fecha de Expiración:
                    <DayPickerInput
                        onDayChange={this.handleExpirationChange}
                        style={{display:'block'}}/>
                </label>
                <TextInput
                    classname='w160'
                    label='Horario:'
                    name='time'
                    type='text'
                    placeholder='Ex: 8:00 - 12:00'
                    touched={this.state.formControls.time.touched}
                    valid={this.state.formControls.time.valid}
                    value={this.state.formControls.time.value}
                    onChange={this.changeHandler} />
                <TextInput
                    classname='w600'
                    label='Lugar:'
                    name='place'
                    type='text'
                    touched={this.state.formControls.place.touched}
                    valid={this.state.formControls.place.valid}
                    value={this.state.formControls.place.value}
                    onChange={this.changeHandler} />
                <input
                    className='button'
                    type='submit'
                    value='Agregar Certificación' />
                <input
                    className='button gray'
                    type='button'
                    onClick={this.goToHome.bind(this)}
                    value='Cancelar' />
            </form>
        </section>
        );
    }
}

