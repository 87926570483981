import React from 'react';

const TextInput=(props)=>{

    let formControl=(props.classname||'');

    if (props.touched && !props.valid) {
        formControl='control-error '+(props.classname||'');
    }

    return (
        <label className={formControl}>
            {props.label}
            <input
                type={props.type}
                name={props.name}
                onChange={props.onChange}
                value={props.value}
                />
        </label>
    );
}

export default TextInput;
