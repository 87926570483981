import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './ParticipantForm.scss';
import validate from '../functions/validator';
import TextInput from '../TextInput';

export default class ParticipantForm extends Component {
    constructor(props){
        super(props);
        this.state={
            formControls:{
                name:{
                    value:'',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                        isStringWithTilde: true
                    }
                },
                company:{
                    value:'',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                        isStringNumberSymbol: true
                    }
                },
                phone:{
                    value:'',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                        isNumber: true
                    }
                },
                email:{
                    value:'',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                        isEmail: true
                    }
                }
            }
        };
        this.certification='';
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.goToParticipants=this.goToParticipants.bind(this);
    }
    componentDidMount() {
        this.certification=this.props.match.params.certification;
    }
    changeHandler = event => {
        const name=event.target.name,
            value=event.target.value;

        const updatedControls = {
          ...this.state.formControls
        };
        const updatedFormElement = {
          ...updatedControls[name]
        };
        updatedFormElement.value=value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

        updatedControls[name] = updatedFormElement;

        this.setState({
            formControls: updatedControls
        });
    }
    goToParticipants(){
        this.props.history.push('/app/certifications/'+this.certification);
    }
    handleChange(event) {
        this.setState({value: event.target.value});
    }
    handleSubmit(event) {
        event.preventDefault();
        let result={
                certificationId:this.certification
            };

        Object.keys(this.state.formControls)
            .forEach((name)=>{
                result[name]=this.state.formControls[name].value
            });

        axios.post('/participant',result)
        .then(()=>{
            this.goToParticipants();
        });
    }
    render() {
        const certification=this.props.match.params.certification;
        return (
        <section className='container'>
            <article className='routing'>
                <Link to={'/app/certifications/'+certification}>Atrás</Link>
                <Link to={'/app/certifications/'+certification}>X</Link>
            </article>
            <article className='actions'>
                <div className='title'>Agregar Participante</div>
            </article>
            <form className='formcert'
                onSubmit={this.handleSubmit}>
                <TextInput
                    label='Nombre:'
                    type='text'
                    name='name'
                    value={this.state.formControls.name.value}
                    touched={this.state.formControls.name.touched}
                    valid={this.state.formControls.name.valid}
                    onChange={this.changeHandler} />
                <TextInput
                    label='Empresa:'
                    type='text'
                    name='company'
                    touched={this.state.formControls.company.touched}
                    valid={this.state.formControls.company.valid}
                    value={this.state.formControls.company.value}
                    onChange={this.changeHandler} />
                <TextInput
                    classname='w380'
                    label='Teléfono:'
                    type='text'
                    name='phone'
                    touched={this.state.formControls.phone.touched}
                    valid={this.state.formControls.phone.valid}
                    value={this.state.formControls.phone.value}
                    onChange={this.changeHandler} />
                <TextInput
                    classname='w380'
                    label='Correo:'
                    type='text'
                    name='email'
                    touched={this.state.formControls.email.touched}
                    valid={this.state.formControls.email.valid}
                    value={this.state.formControls.email.value}
                    onChange={this.changeHandler} />
                <input
                    className='button'
                    type='submit'
                    value='Agregar Participante' />
                <input
                    className='button gray'
                    onClick={this.goToParticipants.bind(this)}
                    type='button'
                    value='Cancelar' />
            </form>
        </section>
        );
    }
}
