import React, { Component } from 'react';
import './App.scss';
import { Route, Redirect, BrowserRouter as Router, Switch } from 'react-router-dom';

import AppContainer from './components/AppContainer';
import LoginBlock from './components/LoginBlock';
import Auth from './auth';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.isAuth() === true
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)

class App extends Component {
    render() {
        return (
            <div className='wrap'>
                <Router>
                    <Switch>
                        <PrivateRoute path='/app' component={AppContainer} />
                        <Route exact path='/login' component={LoginBlock} />
                        <Redirect exact from='/' to='app' />
                        <Redirect to='/app' />
                    </Switch>
                </Router>
            </div>
            );
    }
}

export default App;
