import Cookies from 'js-cookie';
import axios from 'axios';

const Auth={
    isAuth:()=>{
        let cookie=Cookies.get('cert::auth::ehc');
        if(cookie){
            return JSON.parse(cookie).role==='user';
        }
        return false;
    },
    signin:(body)=>{
        return axios.post('/signin',body);
    },
    signout:async(body)=>{
        return axios.post('/signout',body);
    }
};

export default Auth;
