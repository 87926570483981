import React, { Component } from 'react';
import './Table.scss';

export default class Table extends Component {
    render() {
        return (
            <table>
                <thead>
                    <tr>
                        {this.props.headersTable.map((h)=>
                            <th key={h}>{h}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {this.props.children}
                </tbody>
            </table>
        );
    }
}

