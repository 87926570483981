const validate = (value, rules) => {
  let isValid = true;

  for (let rule in rules) {

    switch (rule) {
      	case 'minLength': isValid = isValid && minLengthValidator(value, rules[rule]); break;

        case 'isRequired': isValid = isValid && requiredValidator(value); break;

        case 'isEmail': isValid = isValid && emailValidator(value); break;

        case 'isLetter':  isValid = isValid && letterValidator(value); break;

        case 'isString':  isValid = isValid && stringValidator(value); break;

        case 'isStringNumberSymbol':  isValid = isValid && stringNumberSymbolValidator(value); break;

        case 'isStringWithTilde':  isValid = isValid && stringWithTildeValidator(value); break;

        case 'isStringWithNumberDash':  isValid = isValid && stringWithNumberDashValidator(value); break;

        case 'isNumber':  isValid = isValid && numberValidator(value); break;

        case 'isDate':  isValid = isValid && dateValidator(value); break;

      	default: isValid = true;
    }

  }

  return isValid;
}

/**
 * Check if is date
 * @param  value
 * @return
 */
const dateValidator = (value) => {
    return !!Date.parse(value);
};

/**
 * Check if value has number and +
 * @param  value
 * @return
 */
const numberValidator = (value) => {
    return /^[0-9+]+$/i.test(value);
};

/**
 * Check if value has string, number and dashes
 * @param  value
 * @return
 */
const stringWithNumberDashValidator = (value) => {
    return /^[a-zA-Z0-9-: ]+$/i.test(value);
};

/**
 * Check if value has string with tildes
 * @param  value
 * @return
 */
const stringWithTildeValidator = (value) => {
    return /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$/i.test(value);
};

/**
 * Check if value has string, number and some symbols
 * @param  value
 * @return
 */
const stringNumberSymbolValidator = (value) => {
    return /^[a-zA-ZÀ-ÿ0-9.,/#\u00f1\u00d1 ]+$/i.test(value);
};

/**
 * Check if is a string
 * @param  value
 * @return
 */
const stringValidator = (value) => {
    return /^[a-zA-Z ]+$/i.test(value);
};

/**
 * Check if is a letter
 * @param  value
 * @return
 */
const letterValidator = (value) => {
    return /^[a-zA-Z ]+$/i.test(value);
};

/**
 * minLength Val
 * @param  value
 * @param  minLength
 * @return
 */
const minLengthValidator = (value, minLength) => {
    return value.length >= minLength;
};

/**
 * Check to confirm that field is required
 *
 * @param  value
 * @return
 */
const requiredValidator = value => {
    if(typeof value==='string'){
        return value.trim() !== '';
    }else{
        return value!==undefined;
    }
};

/**
 * Email validation
 *
 * @param value
 * @return
 */
const emailValidator = value => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
};



export default validate;
