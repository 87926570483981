import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './LoginBlock.scss';
import Auth from '../../auth';
import validate from '../functions/validator';
import TextInput from '../TextInput';

export default class LoginBlock extends Component {
    constructor(props) {
        super(props);
        this.state={
            formIsValid:false,
            formControls:{
                email:{
                    value:'',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                        isEmail: true
                    }
                },
                password:{
                    value:'',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true
                    }
                }
            }
        };

        this.showItem=this.showItem.bind(this);
        this.changeHandler=this.changeHandler.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }
    changeHandler=event=>{
        const name=event.target.name,
            value=event.target.value;

        const updatedControls={
            ...this.state.formControls
        };
        const updatedFormElement={
            ...updatedControls[name]
        };

        updatedFormElement.value=value;
        updatedFormElement.touched=true;
        updatedFormElement.valid=validate(value,updatedFormElement.validationRules);

        updatedControls[name]=updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid=updatedControls[inputIdentifier].valid && formIsValid;
        }

        this.setState({
            formControls:updatedControls,
            formIsValid:formIsValid
        });
    }
    handleSubmit=async(event)=>{
        event.preventDefault();
        if(!this.state.formIsValid){
            return;
        }
        let result={};

        Object.keys(this.state.formControls)
            .forEach((name)=>{
                result[name]=this.state.formControls[name].value;
            });

        Auth.signin(result)
        .then((response)=>{
            if(response.data.ok){
                this.props.history.push('/');
            }else{
                //TODO The user is invalid
            }
        });
    };
    showItem(item) {
        this.props.showItem(item);
    }

    render() {
        if(Auth.isAuth()){
            return <Redirect to='/'/>;
        }

        return (
            <section className='login'>
                <div className='title'>
                    <img src='/ehcgroup.png' alt='ehcgroup logo'/>
                    <h4>Gestión de certificaciones y cursos de Ethical Hacking Consultores</h4>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <h3>Login</h3>
                    <TextInput
                        label='Email:'
                        name='email'
                        type='text'
                        touched={this.state.formControls.email.touched}
                        valid={this.state.formControls.email.valid}
                        value={this.state.formControls.email.value}
                        onChange={this.changeHandler} />
                    <TextInput
                        label='Password:'
                        name='password'
                        type='password'
                        touched={this.state.formControls.password.touched}
                        valid={this.state.formControls.password.valid}
                        value={this.state.formControls.password.value}
                        onChange={this.changeHandler} />
                    <input
                        className='button'
                        type='submit'
                        value='Login' />
                </form>
                <div className='deco'>
                    <div className='circle'/>
                    <div className='circle'/>
                    <div className='circle'/>
                    <div className='line'/>
                    <div className='line'/>
                    <div className='line'/>
                    <div className='square'/>
                    <div className='square'/>
                    <div className='square'/>
                    <div className='lines'>
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                    </div>
                </div>
            </section>
        )
    }
}
